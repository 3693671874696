<template>
    <div class="bg-white">
        <div class="max-w-lg mx-auto py-2 sm:px-0 px-1 rounded trip-create">
            <landing-page></landing-page>
            <!-- <trip-create-form type="individual-page"></trip-create-form> -->
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import TripSearchSection from "@/components/molecule/home/TripSearch";
import ShortAboutSection from "@/components/molecule/home/ShortAbout";
import FeedSection from "@/components/molecule/feed/PostTripSection";
import AppInfoSection from "@/components/molecule/home/AppInfo";
import RegistrationSection from "@/components/molecule/home/Registration";
import HowItWorkSection from "@/components/molecule/home/HowItWork";
import TestimonialSection from "@/components/molecule/home/Testimonial";
import Stat from "@/components/molecule/home/Stat.vue";
import BookingManager from "@/services/module/booking";
import userMixin from "@/mixins/userMixin";
import TripCreateForm from "@/components/molecule/company/TripCreateForm.vue";
import LandingPage from '../components/molecule/home/LandingPage.vue';
// import PassengerReqCard from "@/components/molecule/card/passengers-req/passengers-req";
// import ProviderReqCard from "@/components/molecule/card/providers-req/providers-req";
export default {
    name: "Home",
    mixins: [userMixin],
    components: {
        TripCreateForm,
        TripSearchSection,
        ShortAboutSection,
        FeedSection,
        AppInfoSection,
        RegistrationSection,
        HowItWorkSection,
        TestimonialSection,
        Stat,
        LandingPage
        // PassengerReqCard,
        // ProviderReqCard,
    },

    data() {
        return {
            bookingManager: new BookingManager(),
            filter: {
                bookingStatus: "",
                tripStatus: "active"
            },
        }
    },

    computed: {
        ...mapGetters({
            user: "user/getUser",
            bookings: "bookings/getBookingList",
        }),

        apiEnd() {
            return "booking-user/index";
        },
    },

    methods: {
        ...mapMutations({
            setBookings: "bookings/setBookings",
        }),

        async fetchBookingLists() {
            try {
                let res = await this.bookingManager.bookings(this.apiEnd, this.filter);
                // let res = await this.bookingManager.bookings(this.apiEnd+'?status=confirmed+active+hold+requested');
                if (res.success) {
                    let trip = res.data.data;
                    /*let validTrips = res.data.data.filter((item) => {
                      return item.trip.status !== 'canceled' && item.trip.status !== 'expired'
                    })*/
                    this.setBookings(trip);
                    localStorage.setItem('bookingProvider', JSON.stringify(trip))
                }
            } catch (err) {
                if (err.response?.status === 500) {
                    console.log(err.response?.statusText)
                    return;
                }
                console.log("Something went wrong")
            }
        },

        notificationPermission() {
            if (Notification.permission === "granted") {
                console.log('Notification granted')
            } else if (Notification.permission !== "denied") {
                Notification.requestPermission().then((permission) => {
                    if (permission === "granted") {
                        console.log('Notification granted')
                    } else {
                        console.log('Notification denied')
                    }
                });
            }
        }
    },

    created() {
        if (this.userType) {
            this.fetchBookingLists();
        } else {
            this.$router.push({name: "Signup"});
        }
    },
};
</script>
<style>
.trip-create {
    background-color: #F8FAFC;
}
</style>
