export const links = [
	{
		title: "profile",
		link: "customerProfile",
		type: "customer",
		icon: "mdi mdi-account",
	},

	{
		title: "trips",
		link: "RequestTrips",
		type: "customer",
	},

	{
		title: "Bids",
		link: "ProviderBookingFeed",
		type: "provider",
	},

	{
		title: "Payment",
		link: "Wallet",
		type: "provider",
	},

	{
		title: "Trip Request",
		link: "TripCreate",
		type: "provider",
	},

	{
		title: "Trip Request",
		link: "TripCreate",
		type: "customer",
	},

	{
		title: "Trip Request",
		link: "TripCreate",
		type: "driver",
	},

	{
		title: "Add Car",
		link: "CarIndex",
		type: "provider",
	},

];

export const linksWithIcon = [

	{
		title: "ট্রিপ হিস্ট্রি",
		link: "RequestTrips",
		type: "customer",
		icon: "mdi mdi-car-multiple",
	},

	{
		title: "প্রোফাইল",
		link: "customerProfile",
		type: "customer",
		icon: "mdi mdi-account",
	},

];
