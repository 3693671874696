const priceRange = {
    methods: {
        getPriceRange(seat, type, distance) {

            let distanceKM = distance / 1000;
            distanceKM = distanceKM.toFixed(2);
            let basePrice = 700;
            let perKM = 18;
            if (seat === 4) {
                basePrice = 700;
                perKM = 18;
            } else if (seat === 7) {
                basePrice = 2000;
                perKM = 27;
            } else if (seat === 10) {
                basePrice = 2000;
                perKM = 34;
            }
            let min = basePrice + (distanceKM * perKM);

            // max price is 35% more than min price
            let max = min + (min * 0.35);

            // if type is round then add 50% more
            if (type === 'round') {
                min = min + (min * 0.5);
                max = max + (max * 0.5);
            }
            console.log("Min: ", min)
            console.log("Max: ", max)

            return {
                min: min,
                max: max
            }
        }
    }
}

export default priceRange;