<template>
    <div>
        <select
            v-model="selectedDate"
            class="bg-white p-0 w-full"
            @change="changeDate"
        >
            <option
                v-for="date in selectableDates"
                :value="date"
            >
                {{ displayDate(date) }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    name: 'SelectCustomDate',
    data() {
        return {
            selectedDate: null,
            minDate: null,
            maxDate: null
        };
    },
    computed: {
        selectableDates() {
            const dates = [];
            let currentDate = new Date(this.minDate);

            while (currentDate <= new Date(this.maxDate)) {
                dates.push(this.formatDate(currentDate));
                currentDate = this.getNextDate(currentDate);
            }
            return dates;
        }
    },
    methods: {
        formatDate(date) {
            const formattedDate = new Date(date);
            const year = formattedDate.getFullYear();
            const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
            const day = String(formattedDate.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },

        displayDate(date) {
            const formattedDate = new Date(date);
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            const formattedString = formattedDate.toLocaleDateString('bn-BD', options);
            return formattedString;
        },

        getNextDate(date) {
            const nextDate = new Date(date);
            nextDate.setDate(nextDate.getDate() + 1);
            return nextDate;
        },
        changeDate() {
            this.$emit('change-date', this.selectedDate);
        }
    },
    mounted() {
        const today = new Date();
        this.minDate = today.toISOString().split('T')[0];
        const nextFiveDays = new Date(today);
        nextFiveDays.setDate(today.getDate() + 4);
        this.maxDate = nextFiveDays.toISOString().split('T')[0];
        this.selectedDate = this.minDate;
    }
};
</script>
