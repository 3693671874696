<template>
    <div class="bg-white text-gray-700 py-5 md:block hidden">

        <div class="container mx-auto mt-10 border-gray-500 pb-8 grid place-content-center">
            <router-link class="footer-logo-image" to="/">
                <img src="/images/aside-square-black.png" class="" alt="returntripbd"/>
            </router-link>
            <div class="social-links">
                <div class="social-icons flex gap-10 items-center justify-center mt-8">
                    <a target="_blank" href="https://www.facebook.com/returntrip.com.bd">
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="22" viewBox="0 0 11 22" fill="none">
                            <path
                                d="M6.6721 7.21043V5.3054C6.6721 5.01862 6.69206 4.7933 6.71202 4.64991C6.73198 4.48603 6.79186 4.34264 6.89166 4.19925C6.99146 4.05587 7.13118 3.95344 7.33078 3.89199C7.53038 3.83054 7.80982 3.81006 8.14914 3.81006H10.0054V0H7.03138C5.31481 0 4.09725 0.409683 3.35872 1.24953C2.6202 2.08939 2.24096 3.29795 2.24096 4.93669V7.21043H0.0253906V11H2.24096V22H6.6721V11H9.62619L10.0254 7.21043H6.6721Z"
                                fill="#D71920"/>
                        </svg>
                    </a>
                    <a target="_blank" href="https://www.linkedin.com/company/return-trip/">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="#D71920" stroke="#D71920" stroke-width="2" stroke-linecap="round"
                             stroke-linejoin="round" class="feather feather-linkedin">
                            <path
                                d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                            <rect x="2" y="9" width="4" height="12"></rect>
                            <circle cx="4" cy="4" r="2"></circle>
                        </svg>
                    </a>
                    <a target="_blank" href="https://www.youtube.com/channel/UC1-heJWk9Kr6ziJiNsLUHEA">
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="20" viewBox="0 0 29 20" fill="none">
                            <path
                                d="M28.011 7.64988C28.011 7.09832 27.963 6.3789 27.8671 5.51559C27.7952 4.65228 27.6753 3.88489 27.5314 3.21343C27.3635 2.44604 27.0038 1.82254 26.4522 1.29496C25.9007 0.767386 25.2532 0.479616 24.5098 0.383693C22.2076 0.119904 18.7064 0 14.0302 0C9.35393 0 5.85273 0.119904 3.55057 0.383693C2.80717 0.479616 2.15968 0.767386 1.60812 1.29496C1.05657 1.82254 0.696853 2.44604 0.528988 3.21343C0.361122 3.88489 0.265199 4.65228 0.169276 5.51559C0.0973331 6.3789 0.0493714 7.09832 0.0253906 7.64988C0.0253906 8.22542 0.0253906 8.99281 0.0253906 10C0.0253906 11.0072 0.0253906 11.7746 0.0493714 12.3501C0.0493714 12.9017 0.0973331 13.6211 0.193256 14.4844C0.241218 15.3477 0.361122 16.1151 0.505007 16.7866C0.672873 17.554 1.03258 18.1775 1.58414 18.705C2.1357 19.2326 2.78318 19.5204 3.52659 19.6163C5.82875 19.8801 9.32995 20 14.0062 20C18.6825 20 22.1837 19.8801 24.4858 19.6163C25.2292 19.5444 25.8767 19.2326 26.4283 18.705C26.9798 18.1775 27.3395 17.554 27.5074 16.7866C27.6753 16.1151 27.7712 15.3477 27.8671 14.4844C27.9391 13.6211 27.987 12.9017 28.011 12.3501C28.011 11.7986 28.035 11.0072 28.035 10C28.035 8.99281 28.035 8.22542 28.011 7.64988ZM19.5698 10.8393L11.5602 15.8513C11.4163 15.9472 11.2484 16.0192 11.0326 16.0192C10.8647 16.0192 10.7208 15.9712 10.553 15.8993C10.2172 15.7074 10.0254 15.4197 10.0254 15.012V5.01199C10.0254 4.60432 10.1933 4.31655 10.553 4.1247C10.9127 3.93285 11.2484 3.95683 11.5602 4.14868L15.565 6.65468L19.5698 9.16067C19.8815 9.32854 20.0494 9.61631 20.0494 10C20.0254 10.3837 19.8815 10.6715 19.5698 10.8393Z"
                                fill="#D71920"/>
                        </svg>
                    </a>
                </div>
            </div>
            <div
                class="px-3 flex justify-center items-center flex-wrap uppercase mt-10"
            >
                <router-link :to="item.route" v-for="(item, i) in links" :key="i">
                    <span class="text-xs text-gray-500">{{ item.name }}</span>
                    <span class="mx-2 text-gray-500" v-if="i + 1 < links.length">|</span>
                </router-link>
            </div>
        </div>
    </div>

    <div class="bg-gray-800 py-8 mb-20 sm:mb-0 md:block hidden">
        <p class="capitalize text-center text-xs font-semibold text-gray-300">
            Copyrights © <span id="year"></span> All Rights
            <span class="text-primaryLight"> ReturnTrip</span>
        </p>
    </div>

    <BottomNav/>
</template>

<script>
import {links} from "@/data/footerlinks";
import SocialBox from "@/components/atom/SocialBox";
import BottomNav from "@/components/layout/BottomNav";

export default {
    nam: "Footer",
    components: {
        SocialBox,
        BottomNav,
    },
    data() {
        return {
            links,
        };
    },

    mounted() {
        document.getElementById("year").innerHTML = new Date().getFullYear();
    },
    props: {
        footerLogo: {
            default: "/images/logo.png",
        },
    },
};
</script>

<style>
.footer-logo-image {
    width: 100%;
    max-width: 300px;
    margin: auto;
    display: flex;
    justify-content: center;
}
</style>
