<template>
	<div class="flex flex-col items-center">
		<h4 class="py-5 text-gray-500">{{title}}</h4>
      <svg xmlns="http://www.w3.org/2000/svg" width="133" height="134" viewBox="0 0 133 134" fill="none">
          <g opacity="0.5" clip-path="url(#clip0_1748_17931)">
              <path d="M46.5137 40.429C46.5137 38.496 48.0807 36.929 50.0137 36.929H118.914C120.847 36.929 122.414 38.496 122.414 40.429V129.5C122.414 131.433 120.847 133 118.914 133H50.0137C48.0807 133 46.5137 131.433 46.5137 129.5V40.429Z" fill="#FF4181" stroke="white"/>
              <path d="M34.5391 28.4526C34.5391 26.5196 36.1061 24.9526 38.0391 24.9526H106.94C108.873 24.9526 110.44 26.5196 110.44 28.4526V117.524C110.44 119.457 108.873 121.024 106.94 121.024H38.0391C36.1061 121.024 34.5391 119.457 34.5391 117.524V28.4526Z" fill="#FF755C" stroke="white"/>
              <path d="M22.5625 16.4763C22.5625 14.5433 24.1295 12.9763 26.0625 12.9763H94.963C96.896 12.9763 98.463 14.5433 98.463 16.4763V105.547C98.463 107.48 96.896 109.047 94.963 109.047H26.0625C24.1295 109.047 22.5625 107.48 22.5625 105.547V16.4763Z" fill="#FF9356" stroke="white"/>
              <path d="M86.4864 93.571C86.4864 95.504 84.9194 97.071 82.9864 97.071H14.0859C12.1529 97.071 10.5859 95.504 10.5859 93.571V4.5C10.5859 2.567 12.1529 1 14.0859 1H63.457L86.4864 24.0294V93.571Z" fill="#FFCE47" stroke="white"/>
              <path d="M85.7792 23.3223H64.1641V1.70711L85.7792 23.3223Z" fill="#FFEBB4" stroke="white"/>
              <path d="M33 63.5L60 36.5M33 36.5L60 63.5" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <defs>
              <clipPath id="clip0_1748_17931">
                  <rect width="133" height="133" fill="white" transform="translate(0 0.5)"/>
              </clipPath>
          </defs>
      </svg>
	</div>
</template>

<script>
	export default{
		name: 'NoData',
		props: {
			title: {
				type: String,
				default: 'No record found'
			},
			image: {
				type: String,
				default: '/images/no_data_availible.png'
			},

			imageStyle: {
				type: String,
				default: 'w-64'
			}
		}
	}
</script>