<template>
    <div class="rtb-landing-page max-w-lg mx-auto rtb-lading-page shadow">
        <div class="swiper-container splash-rtb max-w-md mx-auto rounded-lg">
            <div class="swiper-wrapper">
                <div class="">
                    <swiper
                        :loop="true"
                        :autoplay="{delay: 5000}"
                        :slides-per-view='1'
                        :space-between="10"
                        :pagination="{
                            clickable: true
                        }"
                        :modules="modules"
                    >
                        <swiper-slide
                            v-for="(item, index) in images"
                            class="max-w-full overflow-hidden rounded-sm"
                        >
                            <img :src="item.src" alt="Slide Image" >
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>

        <div class="flex justify-center gap-2 p-2">
            <div
                class="w-1/2 py-4 flex flex-col justify-center items-center border border-gray-300 rounded-md cursor-pointer"
                @click="$router.push('/trip-request')"
            >
                <img class="w-12 h-12" src="images/icons/rent-a-car.png" alt="icon">
                <p class="middle-text">রেন্ট এ কার</p>
            </div>
<!--            <div
                class="w-1/2 py-4 flex flex-col justify-center items-center border border-gray-300 rounded-md cursor-pointer"
                @click="setOfferDialog"
            >
                <img class="w-12 h-12" src="images/icons/return-car.png" alt="icon">
                <p class="middle-text">রিটার্ন কার</p>
            </div>-->
        </div>

        <div class="running-trips h-fit grid gap-6 bg-white p-2 rounded-lg">
            <div class="single-trip" v-for="trip in tripList" :key="trip.id">
                <div class="trip-heading flex items-center px-3 justify-between text-base">
                    <div class="time-remaining flex items-center gap-2">
                          <span class="relative flex h-3 w-3 -mt-1">
                                <span
                                    class="animate-ping absolute inline-flex h-full w-full rounded-full opacity-75"
                                    :class="trip.status === 'active' ? 'bg-green-400' : 'bg-red-400'"
                                >
                                </span>
                                <span
                                    class="relative inline-flex rounded-full h-3 w-3"
                                    :class="trip.status === 'active' ? 'bg-green-500' : 'bg-red-500'"
                                ></span>
                          </span>
                        <Countdown :trip="trip"/>
                    </div>
                    <div class="trip-state">
                        <p v-if="trip.status === 'active'" class="text-xs">বিডিং চলছে</p>
                        <p v-if="trip.status ==='extended'" class="text-xs text-red-500">বিড সম্পন্ন হয়েছে। পছন্দমত বিড
                            কনফার্ম করুন</p>
                    </div>
                    <div class="trip-details flex items-center gap-2 cursor-pointer" @click="navigateToTrip(trip.id)">
                        <p>বিস্তারিত</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M8.54385 5.95285C8.83594 6.22828 9 6.60164 9 6.99092C9 7.3802 8.83594 7.75356 8.54385 8.02899L2.6626 13.57C2.37 13.8454 1.9732 14.0001 1.5595 14C1.1458 13.9999 0.749079 13.845 0.456615 13.5695C0.164152 13.2939 -9.74591e-05 12.9202 4.3385e-08 12.5306C9.75458e-05 12.1409 0.164534 11.7673 0.457135 11.4919L5.23617 6.99092L0.457135 2.48999C0.172875 2.21306 0.0154739 1.84207 0.0188335 1.45691C0.0221932 1.07174 0.186044 0.703231 0.475097 0.430742C0.76415 0.158253 1.15528 0.00358899 1.56423 6.1677e-05C1.97319 -0.00346563 2.36726 0.144426 2.66156 0.411883L8.54489 5.95187L8.54385 5.95285Z"
                                  fill="#4CBC48"/>
                        </svg>
                    </div>
                </div>
                <div class="trip-route p-2 flex items-center justify-between gap-2">
                    <div class="route-icon w-1/2 flex items-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M7.4375 13.125C6.27812 13.125 5.33203 12.9539 4.59922 12.6117C3.86641 12.2695 3.5 11.8281 3.5 11.2875C3.5 10.9302 3.65859 10.6189 3.97578 10.3534C4.29297 10.088 4.73047 9.87875 5.28828 9.72563L5.66562 10.8894C5.47969 10.9404 5.31016 11.0043 5.15703 11.0811C5.00391 11.1579 4.9 11.2267 4.84531 11.2875C4.9875 11.4508 5.31562 11.5938 5.82969 11.7162C6.34375 11.8387 6.87969 11.9 7.4375 11.9C7.99531 11.9 8.53409 11.8387 9.05384 11.7162C9.57359 11.5938 9.90434 11.4508 10.0461 11.2875C9.99141 11.2262 9.8875 11.1572 9.73437 11.0805C9.58125 11.0037 9.41172 10.94 9.22578 10.8894L9.60312 9.72563C10.1609 9.87875 10.5958 10.088 10.9077 10.3534C11.2197 10.6189 11.3754 10.9302 11.375 11.2875C11.375 11.8285 11.0086 12.2702 10.2758 12.6123C9.54297 12.9545 8.59687 13.1254 7.4375 13.125ZM7.4375 11.2875C7.31719 11.2875 7.20781 11.2542 7.10937 11.1877C7.01094 11.1211 6.93984 11.0319 6.89609 10.92C6.64453 10.1952 6.32734 9.58781 5.94453 9.09781C5.56172 8.60781 5.18984 8.13823 4.82891 7.68906C4.47891 7.2399 4.1755 6.77542 3.91869 6.29562C3.66187 5.81583 3.53325 5.22375 3.53281 4.51937C3.53281 3.49854 3.91016 2.63594 4.66484 1.93156C5.41953 1.22719 6.34375 0.875 7.4375 0.875C8.53125 0.875 9.45547 1.22719 10.2102 1.93156C10.9648 2.63594 11.3422 3.49854 11.3422 4.51937C11.3422 5.22375 11.2164 5.81583 10.9648 6.29562C10.7133 6.77542 10.407 7.2399 10.0461 7.68906C9.69609 8.13823 9.32706 8.60781 8.939 9.09781C8.55094 9.58781 8.23091 10.1952 7.97891 10.92C7.93516 11.0323 7.86406 11.1217 7.76562 11.1883C7.66719 11.2548 7.55781 11.2879 7.4375 11.2875ZM7.4375 5.82094C7.82031 5.82094 8.14844 5.69333 8.42187 5.43812C8.69531 5.18292 8.83203 4.87667 8.83203 4.51937C8.83203 4.16208 8.69531 3.85583 8.42187 3.60062C8.14844 3.34542 7.82031 3.21781 7.4375 3.21781C7.05469 3.21781 6.72656 3.34542 6.45312 3.60062C6.17969 3.85583 6.04297 4.16208 6.04297 4.51937C6.04297 4.87667 6.17969 5.18292 6.45312 5.43812C6.72656 5.69333 7.05469 5.82094 7.4375 5.82094Z" fill="#9D9D9D"/>
                        </svg>
                        <!-- Mobile view: Slice the address -->
                        <div class="ml-2 block md:hidden">
                            <p>{{ trip.pickup_full_address.slice(0, 15) }}...</p>
                        </div>
                        <!-- PC view: Full address -->
                        <div class="ml-2 hidden md:block">
                            <p>{{ trip.pickup_full_address.slice(0, 25) }}...</p>
                        </div>
                    </div>
                    <div class="route-icon w-1/2 flex items-center justify-start">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M3 1V13V1ZM11 7.46154H3V1H11L8.33333 4.23077L11 7.46154Z" fill="#9D9D9D"/>
                            <path d="M3 1V13M3 1V7.46154H11L8.33333 4.23077L11 1H3Z" stroke="#9D9D9D" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <!-- Mobile view: Slice the address -->
                        <div class="ml-2 block md:hidden">
                            <p>{{ trip.destination_full_address.slice(0, 15) }}...</p>
                        </div>
                        <!-- PC view: Full address -->
                        <div class="ml-2 hidden md:block">
                            <p>{{ trip.destination_full_address.slice(0, 25) }}...</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex';
import TripManager from "@/services/module/trip";
import Countdown from '../feed/Countdown.vue';

//import Swiper from 'swiper';
/*import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';*/

import { Swiper, SwiperSlide } from 'swiper/vue';
import {Autoplay, Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

export default {
    data() {
        return {
            tripManager: new TripManager(),
            tripList: null,
            swiper: null,
            images: [
                {
                    src: "images/feature.jpg",
                },
            ],
        }
    },
    components: {
        Countdown,
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Pagination, Autoplay],
        };
    },

    methods: {
        ...mapMutations({
            setDialogComponent: "setDialogComponent",
        }),
        navigateToTrip(id) {
            this.$router.push(`/trip-bidding/${id}`)
        },
        goToEstimatedFare() {
            this.$router.push({name: 'EstimatedFare'})
        },
        async fetchTrips() {
            let requestTripsRes = await this.tripManager.trips({
                descOrderColumnName: "created_at",
                status: "active+extended",
            });
            if (requestTripsRes.success) {
                this.tripList = requestTripsRes.data
            }
        },

        setOfferDialog() {
            this.setDialogComponent('OfferModal')
        },
        navigateTo(path) {
            this.$router.push(path);
        },

    },
    mounted() {
        this.fetchTrips();
    },
}
</script>

<style lang="scss" scoped>
.rtb-landing-page {
    height: calc(100vh - 100px);
    font-family: 'Noto Sans Bengali',serif;
    background: #FFF;
}

.rtb-title {
    color: #D72028;
    font-weight: bold;
}

.max-width-360 {
    max-width: 360px;
    margin: 2px auto;
}

.middle-text {
    font-size: 10px;
    color: #1A1A1A;
    font-family: 'Noto Sans Bengali', sans-serif;
    font-weight: 700;

}


.running-trips {
    .single-trip {
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
        border: 1px solid #C7CDD2;

        .trip-heading {
            height: 40px;
            border-radius: 4px;
            background: #EDF8ED;
        }

        .time-remaining, .trip-state, .trip-details {
            p {
                color: #4CBC48;
                font-weight: 600;
            }
        }

        .trip-route {
            font-size: 12px;

            .route-icon {
                background-color: #F4F4F4;
                border-radius: 4px;
                padding: 8px 12px;
                p {
                    color: #1A1A1A;
                    font-weight: 600;
                    font-family: 'Noto Sans Bengali', sans-serif;
                    font-size: 12px;
                }
            }
        }
    }
  }

  @media (max-width: 350px) {
    .estimated-fare {
      .est-title, .est-btn-text {
      font-size: 11px;
      }
      .cost-image {
        height: 24px;
        width: 24px;
      }
      svg {
        height: 10px;
        width: 10px;
      }
    }
  }
</style>